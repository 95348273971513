.pagetitle {
  color: var(--h_text);
  font-family: var(--font);
  font-size: var(--pagetitle);
  font-style: normal;
  font-weight: var(--fw7);
  margin: 12px 0;
}

.page-section {
  margin: 12px 0;
}

.pagediv {
  margin: 20px 0;
}

/*# sourceMappingURL=index.74c997be.css.map */
